import React from 'react'
import Login from './components/Login/Login'
import Main from './components/Main/Main'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/main">
          <Main />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App
