import React from 'react'
import SettingsModal from '../SettingsModal/SettingsModal'
import './ScalarForm.css'

class ScalarForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            spreadsheetId: '',
            sheetName: '',
            query: '',
            title: '',
            queries: [],
            settings: [],
            removed: [],
            success: null,
            errors: null,
            replace: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    componentDidMount() {
        this.getQueries()
        this.getUserQueries()
    }

    validateData() {
        const spreadsheetId = this.state.spreadsheetId.trim()
        const sheetName = this.state.sheetName.trim()
        const query = this.state.query.trim()

        if (!spreadsheetId.length || !sheetName.length || !query.length) {
            this.setState({
                errors: 'All fileds are required.',
                success: null
            })
            return false
        }
        return true
    }

    setData() {
        if (this.validateData()) {
            const { spreadsheetId, sheetName, query, title, replace } = this.state
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/scalar/insert`
            const data = {
                spreadsheetId,
                sheetName,
                query,
                title,
                replace,
                username: items ? items.username : null
            }
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token : null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({
                            success: response.data,
                            errors: null
                        }, () => {
                            this.getUserQueries()
                            setTimeout(() => {
                                this.setState({
                                    success: null,
                                    errors: null
                                })
                            }, 4000)
                        })
                    })
                } else if (res.status === 208) {
                    res.json().then(response => {
                        this.setState({
                            errors: null,
                            success: null
                        })
                        if (window.confirm('The spreadsheet already contains this sheet.\n' + 'Are you sure about replace it?')) {
                            this.setState({replace: true})
                            this.setData()
                        }
                    })
                } else if (res.status === 401) {
                    res.json().then(response => {
                        this.setState({
                            errors: response.data,
                            success: null
                        })
                    })
                }
            })
            .catch(error => console.error('Error:', error))
        }
        
    }

    getQueries() {
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/query/getall`
        const data = {username: items ? items.username : null}
        
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': items ? items.token : null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => { this.setState({queries: response.data}) })
            } else if (res.status === 401) {
                res.json().then(response => {
                    this.setState({
                        errors: response.data,
                        success: null
                    })
                })
            }
        })
        .catch(error => console.error('Error:', error))
    }

    getUserQueries() {
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/user/queries`
        const data = {username: items ? items.username: null}
        
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': items ? items.token: null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => { this.setState({settings: response.data}) })
            } else if (res.status === 401 || res.status === 400) {
                res.json().then(response => {
                    this.setState({
                        errors: response.data,
                        success: null
                    })
                })
            }
        })
        .catch(error => console.error('Error:', error))
    }
    
    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            [name]: value,
            title: event.target.selectedOptions ? event.target.selectedOptions[0].innerText : ''
        })
    }

    handleChecked(event) {
        let removed = this.state.removed
        if (event.target.checked) {
            removed.push(event.target.id)
        } else {
            removed.splice(removed.indexOf(event.target.id), 1)
        }
        
        this.setState({removed: removed})
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setData(this.state)
    }

    handleRemove() {
        if (this.state.removed.length) {
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/user/settings/remove`
            const data = {username: items ? items.username: null, removed: this.state.removed}
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token: null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({queries: response.data}, () => {
                            this.getQueries()
                            this.getUserQueries()
                        })
                    })
                } else if (res.status === 401 || res.status === 400) {
                    res.json().then(response => {
                        this.setState({
                            errors: response.data,
                            success: null
                        })
                    })
                }
            })
            .catch(error => console.error('Error:', error))
        } else {
            alert('You must select any query in the list...')
        }
    }

    render() {
        return(
            <>
                <div className='col-md-5 col-sm-12'>
                    <h5>Scalar data form</h5>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="spreadsheetId">Spreadsheet id</label>
                            <input type="text" onChange={this.handleChange} className="form-control" name="spreadsheetId" id="spreadsheetId" placeholder="spreadsheet id"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sheetName">Sheet name</label>
                            <input type="text" onChange={this.handleChange} className="form-control" name="sheetName" id="sheetName" placeholder="sheet name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="query">Data</label>
                            <select id="query" name="query" className="custom-select" onChange={this.handleChange}>
                                <option value="">Select an option</option>
                                {this.state.queries.map(query => {
                                    if (query.queryType === 'scalar') {
                                        return <option key={query._id} value={query._id}>{query.title}</option>
                                    }
                                })}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary">Send</button>
                    </form>
                </div>
                <div className='col-md-6 col-sm-12 my-scroll'>
                    <h5>Your queries</h5>
                    <ul className='list-group list-group-flush'>
                        {this.state.settings.map(query => {
                            if (query.query.queryType === 'scalar') {
                                return <li key={query._id} className='list-group-item d-flex justify-content-between align-items-center'>
                                        <a href="#" className="user-query" data-toggle="modal" data-target={`#modal-${query._id}`}>{query.query.title}</a>
                                        <span className="badge badge-success badge-pill">{query.sheetName}</span>
                                        <div className="form-check">
                                            <input onChange={this.handleChecked} className="form-check-input" type="checkbox" id={query._id}/>
                                        </div>
                                     </li>
                            }
                        })}
                    </ul>
                    <button className="btn btn-danger mt-2" onClick={this.handleRemove}>Remove</button>
                </div>
                <div className='col-md-5 offset-md-2 col-sm-12'>
                    { (this.state.errors && <div className="p-3 mb-2 bg-danger text-white mt-3">{this.state.errors}</div>) }
                    { (this.state.success && <div className="p-3 mb-2 bg-success text-white mt-3 animated fadeOutRight delay-3s">{this.state.success}</div>) }
                </div>
                {this.state.settings.map(query => {
                    if (query.query.queryType === 'scalar') {
                        return <SettingsModal key={query._id} id={`modal-${query._id}`} query={query}/>
                    }
                })}
            </>
        )
    }
}

export default ScalarForm