import React from 'react'
import './Navbar.css'
import {Link} from "react-router-dom"
import Logout from '../Logout/Logout'

class Nabvar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            role: null,
            section: 'home'
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        this.getRole()
    }
    
    getRole() {
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/user/role`
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const data = {username: items ? items.username : null}

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': items ? items.token : null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => {
                    this.setState({role: response.data})
                })
            } else if (res.status === 500) {
                res.json().then(response => {
                    console.log(response.data)
                })
            }
        })
        .catch(error => {console.log(error)})
    }

    handleClick(event) {
        this.setState({section: event.target.name})
    }

    render() {
        return(
            <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="#">Fleks Spreadsheet</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/main" name="home" className={`nav-link ${this.state.section === 'home' ? 'active activeOption' : ''}`} onClick={this.handleClick}>Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/main/settings" name="settings" className={`nav-link ${this.state.section === 'settings' ? 'active activeOption' : ''}`} onClick={this.handleClick}>Settings</Link>
                        </li>
                        {this.state.role === 'admin' &&<li className="nav-item"><Link to="/main/queries" name="queries" className={`nav-link ${this.state.section === 'queries' ? 'active activeOption' : ''}`} onClick={this.handleClick}>Queries</Link></li>}
                    </ul>
                </div>
                <Logout />
            </nav>
        )
    }
}

export default Nabvar