import React from 'react'
import { withRouter } from "react-router-dom"

class Logout extends React.Component {
    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(evenet) {
        evenet.preventDefault()
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/logout`

        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': items ? items.token : null,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        localStorage.removeItem('fleksUserItems')
                        this.props.history.push("/")
                    })
                } else if (res.status === 500) {
                    res.json().then(response => {
                        console.log(response.data)
                    })
                }
            })
            .catch(error => console.error('Error:', error))
    }

    render() {
        return (
            <button className="btn btn-outline-danger my-2 my-sm-0" onClick={this.handleClick}>Logout</button>
        )
    }
}

export default withRouter(Logout)