import React from 'react'

class Home extends React.Component {

    render() {
        return(
            <div className="container">
                <h2>Home</h2>
            </div>
        )
    }
}

export default Home