import React from 'react'
import QueryList from '../QueryList/QueryList'
import './QueryForm.css'

class QueryForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            queryType: '',
            query: '',
            queries: [],
            removed: [],
            success: null,
            errors: null,
            querySuccess: null,
            queryErrors: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChecked = this.handleChecked.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
    }

    componentDidMount() {
        this.getQueries()
    }


    validateData() {
        const title = this.state.title.trim()
        const queryType = this.state.title.trim()
        const query = this.state.title.trim()

        if (!title.length || !queryType.length || !query.length) {
            this.setState({
                errors: 'All fileds are required.',
                success: null
            })
            return false
        }
        return true
    }

    getQueries() {
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/query/getall`
        const data = {username: items ? items.username: null}
        
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': items ? items.token: null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => { this.setState({queries: response.data}) })
            } else if (res.status === 401) {
                res.json().then(response => {
                    alert(response.data)
                })
            }
        })
        .catch(error => console.error('Error:', error))
    }

    setData() {
        if (this.validateData()) {
            const { title, query, queryType } = this.state
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/query/insert`
            const data = {
                title,
                query,
                queryType,
                username: items ? items.username : null
            }
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token : null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({
                            success: response.data,
                            errors: null
                        }, () => {
                            this.getQueries()
                            setTimeout(() => {
                                this.setState({
                                    success: null,
                                    errors: null
                                })
                            }, 4000)
                        })
                    })
                } else if (res.status === 400) {
                    res.json().then(response => {
                        this.setState({
                            errors: response.data,
                            success: null
                        })
                    })
                } else if (res.status === 401) {
                    res.json().then(response => {
                        this.setState({
                            errors: response.data,
                            success: null
                        })
                    })
                }
            })
            .catch(error => console.error('Error:', error))
        }
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState({ [name]: value })
    }

    handleChecked(event) {
        let removed = this.state.removed
        if (event.target.checked) {
            removed.push(event.target.id)
        } else {
            removed.splice(removed.indexOf(event.target.id), 1)
        }
        
        this.setState({removed: removed})
    }

    handleSubmit(event) {
        event.preventDefault()
        this.setData()
    }

    handleRemove() {
        if (this.state.removed.length) {
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/query/remove`
            const data = {removed: this.state.removed}
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token: null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({
                            queries: response.data
                        }, () => {
                            this.getQueries()
                            setTimeout(() => {
                                this.setState({
                                    success: null,
                                    errors: null
                                })
                            }, 4000)
                        })
                    })
                } else if (res.status === 401) {
                    res.json().then(response => {
                        this.setState({
                            queryErrors: response.data,
                            querySuccess: null
                        })
                    })
                } else if (res.status === 500) {
                    alert(res.data)
                }
            })
            .catch(error => console.error('Error:', error))
        } else {
            alert('You must select any query in the list...')
        }
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-sm-12">
                        <h4>Query form</h4>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="title">Tittle query</label>
                                <input type="text" onChange={this.handleChange} className="form-control" name="title" id="title" placeholder="tittle query" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="queryType">Query type</label>
                                <select id="queryType" name="queryType" className="custom-select" value={this.state.queryType} onChange={this.handleChange}>
                                    <option value="">Select an option</option>
                                    <option value="count">Count</option>
                                    <option value="scalar">Scalar</option>
                                    <option value="countHistory">Coun history</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="query">Query</label>
                                <textarea rows="8" onChange={this.handleChange} className="form-control" name="query" id="query" placeholder="All query here..." />
                            </div>
                            <button type="submit" className="btn btn-primary">Send</button>
                        </form>
                        { (this.state.errors && <div className="p-3 mb-2 bg-danger text-white mt-3">{this.state.errors}</div>) }
                        { (this.state.success && <div className="p-3 mb-2 bg-success text-white mt-3 animated fadeOutRight delay-3s">{this.state.success}</div>) }
                    </div>
                    <div className="col-md-6 col-sm-12">
                        {this.state.queryType === '' ? <h5>Select a query type to show the instructions...</h5> : <img src={`/img/${this.state.queryType}_query.png`} alt="Instructions..." className="img-fluid"/>}
                    </div>
                </div>
                <QueryList queries={this.state.queries} queryType={this.state.queryType}/>
            </div>
        )
    }
}

export default QueryForm