import React, {useState, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom"

function PrivateRoute({ children, ...rest}) {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/authenticated`
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Authorization': items ? items.token : null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                setIsAuthenticated(true)
            } else if (res.status === 401) {
                setIsAuthenticated(false)
            }
            setLoading(false)
        })
        .catch(error => {
            setIsAuthenticated(false)
            setLoading(false)
        })
    }, [])

    if (loading) {
        return 'Loading...'
    } else {
        return (
            <Route 
                {...rest}
                render={({location}) => 
                    isAuthenticated ? (children) : (
                        <Redirect
                            to= {{
                                pathname: '/',
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
        )
    }
}

export default PrivateRoute