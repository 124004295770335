import React from 'react'

class QueryList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            queries: [],
            removed: [],
            success: null,
            errors: null,
            querySuccess: null,
            queryErrors: null
        }

        this.handleChecked = this.handleChecked.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.filterList = this.filterList.bind(this)
    }

    handleChecked(event) {
        let removed = this.state.removed
        if (event.target.checked) {
            removed.push(event.target.id)
        } else {
            removed.splice(removed.indexOf(event.target.id), 1)
        }
        
        this.setState({removed: removed})
    }

    handleRemove() {
        if (this.state.removed.length) {
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/query/remove`
            const data = {removed: this.state.removed}
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token: null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({
                            queries: response.data
                        }, () => {
                            this.getQueries()
                            setTimeout(() => {
                                this.setState({
                                    success: null,
                                    errors: null
                                })
                            }, 4000)
                        })
                    })
                } else if (res.status === 401) {
                    res.json().then(response => {
                        this.setState({
                            queryErrors: response.data,
                            querySuccess: null
                        })
                    })
                } else if (res.status === 500) {
                    alert(res.data)
                }
            })
            .catch(error => console.error('Error:', error))
        } else {
            alert('You must select any query in the list...')
        }
    }

    filterList() {
        if (this.props.queryType.trim() !== '') {
            const list = this.props.queries.map((query, index) => {
                if (query.queryType === this.props.queryType) {
                    return <li key={query._id} className='list-group-item d-flex justify-content-between align-items-center'>
                            {`${index+1}. ${query.title}`}
                            <div className="form-check">
                                <input onChange={this.handleChecked} className="form-check-input" type="checkbox" id={query._id}/>
                            </div>
                        </li>
                }
            })
            return list
        }
    }

    render() {
        return(
            <>
            <div className='col-md-7 col-sm-12 my-scroll mb-10'>
                <h5>List of queries</h5>
                <ul className='list-group list-group-flush'>
                    {this.filterList()}
                </ul>
                { (this.state.queryErrors && <div className="p-3 mb-2 bg-danger text-white mt-3">{this.state.queryErrors}</div>) }
                { (this.state.querySuccess && <div className="p-3 mb-2 bg-success text-white mt-3 animated fadeOutRight delay-3s">{this.state.querySuccess}</div>) }
            </div>
            <button className="btn btn-danger mt-2" onClick={this.handleRemove}>Remove</button>
            </>
        )
    }
}

export default QueryList