import React, { useState } from 'react'
import SingleForm from '../SingleForm/SingleForm'
import ScalarForm from '../ScalarForm/ScalarForm'
import CountHistoryForm from '../CountHistoryForm/CountHistoryForm'
import {
    Switch,
    Route,
    Link,
    useRouteMatch
} from "react-router-dom";

function Settings(props) {
    let {path, url} = useRouteMatch()
    const [section, setSection] = useState('count')

    function handleClick(event) {
        setSection(event.target.name)
    }

    return(
        <div className="container">
            <div className="row">
                <div className="col-md-5 col-sm-12">
                    <h4>Query settings</h4>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <Link name="count" to={`${url}/singleForm`} className={`nav-link ${section === 'count' ? 'active' : ''}`} onClick={handleClick}>Count</Link>
                        </li>
                        <li className="nav-item">
                            <Link name="scalar" to={`${url}/scalarForm`} className={`nav-link ${section === 'scalar' ? 'active' : ''}`} onClick={handleClick}>Scalar</Link>
                        </li>
                        <li className="nav-item">
                            <Link name="countHistory" to={`${url}/countHistory`} className={`nav-link ${section === 'countHistory' ? 'active' : ''}`} onClick={handleClick}>History</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='row'>
                <Switch>
                    <Route exact path={`${path}`} component={SingleForm} />
                    <Route path={`${path}/singleForm`} component={SingleForm} />
                    <Route path={`${path}/scalarForm`} component={ScalarForm} />
                    <Route path={`${path}/countHistory`} component={CountHistoryForm} />
                </Switch>
            </div>
        </div>
    )
}

export default Settings