import React, {useState, useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Home from '../Home/Home'
import Settings from '../Settings/Settings'
import QueryForm from '../QueryForm/QueryForm'
import Footer from '../Footer/Footer'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom"
import './Main.css'

function Main(props) {
    let { path } = useRouteMatch()
    const [role, setRole] = useState(null)

    useEffect(() => {
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/user/role`
        const items = JSON.parse(localStorage.getItem('fleksUserItems'))
        const data = {username: items ? items.username : null}

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': items ? items.token : null,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => {
                    setRole(response.data)
                })
            } else if (res.status === 500) {
                res.json().then(response => {
                    console.log(response.data)
                })
            }
        })
        .catch(error => {console.log(error)})
    }, [])

    return (
        <>
            <Navbar />

            <div className='main-wrapper'>
                <Switch>
                    <Route exact path={`${path}`} component={Home} />
                    <Route path={`${path}/settings`} component={Settings} />
                    {role === 'admin' && <Route path={`${path}/queries`} component={QueryForm} />}
                </Switch>
            </div>

            <Footer />
        </>
    )
}

export default Main
