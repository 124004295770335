import React from 'react'
import { Switch, Route, withRouter } from "react-router-dom"
import './Login.css'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            errors: null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            [name]: value
        })
    }

    handleSubmit(event) {
        const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/login`
        const data = {
            username: this.state.username,
            password: this.state.password
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status === 200) {
                res.json().then(response => {
                    this.setState({ errors: null })
                    localStorage.setItem('fleksUserItems', JSON.stringify(response.items))
                    this.props.history.push("/main")
                })
            } else if (res.status === 400) {
                res.json().then(response => {
                    this.setState({ errors: response.data })
                })
            } else if (res.status === 500) {
                res.json().then(response => {
                    console.log(response.data)
                })
            }
        })
        .catch(error => console.error('Error:', error))

        event.preventDefault()
    }

    render() {
        return (
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-center text-white mb-4">Bootstrap 4 Login Form</h2>
                        <div className="row">
                            <div className="col-md-6 mx-auto">

                                <div className="card rounded-0">
                                    <div className="card-header">
                                        <h3 className="mb-0">Login</h3>
                                    </div>
                                    <div className="card-body">
                                        <form className="form" onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input onChange={this.handleChange} type="text" className="form-control form-control-lg rounded-0" name="username" id="username" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input onChange={this.handleChange} type="password" className="form-control form-control-lg rounded-0" name="password" id="password" />
                                            </div>
                                            {/* <div>
                                                <label className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input"/>
                                                <span className="custom-control-indicator"></span>
                                                <span className="custom-control-description small text-dark">Remember me on this computer</span>
                                                </label>
                                            </div> */}
                                            <div className="align-right">
                                                <button type="submit" className="btn btn-success btn-lg" id="btnLogin">Login</button>
                                            </div>
                                        </form>
                                        { (this.state.errors && <div className="p-3 mb-2 bg-danger text-white mt-3">{this.state.errors}</div>) }
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Login)