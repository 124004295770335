import React from 'react'

class SettingsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            spreadsheetId: this.props.query.spreadSheetId,
            sheetName: this.props.query.sheetName,
            title: '',
            errors: null,
            success: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    validateData() {
        const spreadsheetId = this.state.spreadsheetId.trim()
        const sheetName = this.state.sheetName.trim()

        if (!spreadsheetId.length || !sheetName.length) {
            this.setState({
                errors: 'All fileds are required.',
                success: null
            })
            return false
        }
        return true
    }

    setData() {
        if (this.validateData()) {
            const { spreadsheetId, sheetName } = this.state
            const items = JSON.parse(localStorage.getItem('fleksUserItems'))
            const url = `${process.env.REACT_APP_FLEKS_SPREADSHEET_BACK}/settings/query/edit`
            const data = {
                spreadsheetId,
                sheetName,
                query: this.props.query._id,
                username: items ? items.username : null
            }
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Authorization': items ? items.token : null,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                if (res.status === 200) {
                    res.json().then(response => {
                        this.setState({
                            success: response.data,
                            errors: null
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    success: null,
                                    errors: null
                                })
                            }, 4000)
                        })
                    })
                } else if (res.status === 401 || res.status === 400) {
                    res.json().then(response => {
                        this.setState({
                            errors: response.data,
                            success: null
                        })
                    })
                }
            })
            .catch(error => console.error('Error:', error))
        }
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    handleClick(event) {
        this.setData(this.state)
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit query</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="spreadsheetId">Spreadsheet id</label>
                                <input type="text" value={this.state.spreadsheetId} onChange={this.handleChange} className="form-control" name="spreadsheetId" id="spreadsheetId" placeholder="spreadsheet id"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="sheetName">Sheet name</label>
                                <input type="text" value={this.state.sheetName} onChange={this.handleChange} className="form-control" name="sheetName" id="sheetName" placeholder="sheet name"/>
                            </div>
                        </form>
                        <div>
                        { (this.state.errors && <div className="p-3 mb-2 bg-danger text-white mt-3">{this.state.errors}</div>) }
                        { (this.state.success && <div className="p-3 mb-2 bg-success text-white mt-3 animated fadeOutRight delay-3s">{this.state.success}</div>) }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button onClick={this.handleClick} type="button" className="btn btn-primary">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsModal